@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
.side-menu-icon {
    /* font-size: 35px !important; */
    color: #ced7e9 !important;
}

.side-menu-text {
    /* font-size: 18px !important; */
    margin-left: 10px;
    color: #ced7e9 !important;
}

.nav-link:hover>.side-menu-text {
    color: #6460f2 !important;
}

.nav-link:hover>.side-menu-icon {
    color: #6460f2 !important;
}

.side-menu-active {
    color: #6460f2 !important;
    margin-top: 50px !important;
}

* {
    font-family: "Poppins";
}


/* #################################################### */

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background: #6460f2 !important;
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
    padding: 30px;
}

table tr {
    vertical-align: middle;
}

.activeLink {
    color: "red";
    background: "yellow";
}

.pro-sidebar .pro-menu a {
    color: "red" !important;
}

.pro-sidebar .pro-menu {
    padding: 0px;
}

.nav-link {
    color: "yellow" !important;
}


/* ################################################################## */


/* body {
  font-family: helvetica;
  display: flex ;
  flex-direction: column;
  align-items: center;
} */

.chat {
    width: 300px;
    border: solid 1px #eee;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.messages {
    margin-top: 10px;
    /* margin-top: 30px; */
    display: flex;
    flex-direction: column;
}

.message {
    margin-top: 5px;
    margin-bottom: 20px;
    display: inline-block;
    padding: 20px 26px;
}

.yours {
    align-items: flex-start;
}

.yours .message {
    margin-right: 25%;
    background-color: #6e9aec;
    position: relative;
    max-width: 48%;
    color: white;
    border-radius: 20px 20px 20px 0px;
}

.yours .message.last:before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: -7px;
    height: 20px;
    width: 20px;
    background: #6e9aec;
    border-bottom-right-radius: 15px;
}

.yours .message.last:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -10px;
    width: 10px;
    height: 20px;
    background: white;
    border-bottom-right-radius: 10px;
}

.mine {
    align-items: flex-end;
}

.mine .message {
    border-radius: 20px 20px 0px 20px;
    max-width: 48%;
    margin-left: 25%;
    background-color: #dde9ff;
    color: #5d59b4;
    background-attachment: fixed;
    position: relative;
}

.mine .message.last:before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: -8px;
    height: 20px;
    width: 20px;
    background: #dde9ff;
    background-attachment: fixed;
    border-bottom-left-radius: 15px;
}

.mine .message.last:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -10px;
    width: 10px;
    height: 20px;
    background: white;
    border-bottom-left-radius: 10px;
}

.mine p {
    text-align: left;
    margin: 0px;
    width: 100%;
    position: absolute;
    bottom: -26px;
    color: #c4c2e9;
    font-size: 14px;
    left: 0;
}

.yours p {
    text-align: right;
    margin: 0px;
    width: 100%;
    position: absolute;
    bottom: -26px;
    color: #c4c2e9;
    font-size: 14px;
    right: 0;
}

.yours #writer {
    top: -20px;
    width: 100%;
    color: #817eb7;
    text-align: left;
    font-size: 12px;
    left: 0px;
}

.mine #writer {
    top: -20px;
    width: 100%;
    color: #817eb7;
    text-align: right;
    font-size: 12px;
    right: 0px;
}


/* ************************************************************************* */

table {
    border-radius: 50px !important;
    border: none !important;
}

table td {
    border: none !important;
    padding-left: 0px;
    padding-right: 0px;
    /* text-align: center!important; */
    vertical-align: middle !important;
    font-weight: 400;
}

tbody tr:nth-child(odd) {
    background-color: #f3f7ff;
}

tbody tr:nth-child(even) {
    background-color: white;
}

.issueTr {
    background-color: white!important;
}


/*  png converter */

.changed {
    -webkit-filter: hue-rotate(180deg) !important;
    filter: hue-rotate(180deg) !important;
}


/* Select CSS */

select {
    width: 84px;
    height: 42px;
    box-shadow: 19px 10px 53px 7px rgba(27, 30, 123, 0.06);
    /* styling */
    background-color: white;
    border: none;
    border-radius: 8px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;
    /* reset */
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
}


/* arrows */

select.classic {
    background-image: linear-gradient(45deg, transparent 50%, #5d59b4 50%), linear-gradient(135deg, #5d59b4 50%, transparent 50%), linear-gradient(to right, #dde9ff, #dde9ff);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 3.5em;
    background-repeat: no-repeat;
}

select.classic:focus {
    border: none;
    /* background-image: linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    linear-gradient(to right, gray, gray);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border-color: grey;
  outline: 0; */
}

select.round {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), radial-gradient(#ddd 70%, transparent 72%);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
    background-repeat: no-repeat;
}

select.round:focus {
    background-image: linear-gradient(45deg, white 50%, transparent 50%), linear-gradient(135deg, transparent 50%, white 50%), radial-gradient(gray 70%, transparent 72%);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, calc(100% - 0.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
    background-repeat: no-repeat;
    border-color: green;
    outline: 0;
}

select.minimal {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
}

select.minimal:focus {
    background-image: linear-gradient(45deg, green 50%, transparent 50%), linear-gradient(135deg, transparent 50%, green 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: green;
    outline: 0;
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}


/* Custom scrollbar */

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: white;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #807cd6;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.3);
}

.form-group input {
    color: "red" !important;
}

body {
    background-color: #f3f7ff !important;
}

.btn-close {
    color: "#5D59B4" !important;
}

td {
    line-height: 35px !important;
}


/* btn_group style */

#btn_container #btn_group {
    display: none;
}

#btn_container:hover .fa-ellipsis-h {
    display: none;
}

#btn_container:hover #btn_group {
    display: block;
    position: absolute;
    width: 117px;
    right: 20px;
    margin-top: -19px;
    background-color: none;
    padding: 0px;
}


/* Tab color */

.nav-fill .nav-item .nav-link {
    color: #5D59B4;
}

.nav-pills .nav-link.active {
    color: white;
}

.nav-pills .nav-link.active:hover {
    color: white;
}

.nav-pills .nav-link {
    color: #5D59B4;
}

.nav-pills .nav-link:hover {
    color: #5D59B4;
}


/* Sidebar*/

.container-fluid {
    width: 100%;
    padding-right: 0.75rem!important;
    padding-left: 0.75rem!important;
    margin-right: auto!important;
    margin-left: auto!important;
}

.custom::placeholder {
    color: #C4C2E9!important;
    opacity: 1;
}

.classChat:hover {
    cursor: pointer;
}

.accordion-button:not(.collapsed){
    color: #554DF1 !important
}

.accordion-button {
    color: rgb(129, 126, 183) !important;
}

th>svg {
    color: #BFBDFF;
    padding-left: 2px !important;
    width: 16px !important;
    height: 16px !important;
}

th>svg.ascending {
    color: #fff;
    transform: rotate(180deg);
    display: inline-block;
    margin-left: 1em;
}

th>svg.descending {
    color: #fff;
    transform: rotate(360deg);
    display: inline-block;
    margin-left: 1em;
}